html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  text-rendering: optimizeLegibility !important;
  font-size: 10px;
  color: #232323;
  font-weight: 700;
  -webkit-font-smoothing: antialiased !important;
}

body {
  padding-top: constant(safe-area-inset-top) !important; /* iOS 11.0 */
  padding-top: env(safe-area-inset-top) !important; /* iOS 11.2 */
}

table {
  border-bottom: solid 1px #d3d3d3;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 10px;
}

thead tr {
  font-weight: 600;
  border-top: none;
}

tr {
  border-top: solid 1px #d3d3d3;
  height: 30px;
  font-size: 1.2rem;
  font-weight: 400;
}

tr:nth-child(even) {
  background-color: #F1F1F1;
}

td {
  color: #363636;
  padding: 0 0 0 10px;
}

.homeTitle {
  margin-left: 35px;
  font-size: 16px;
}

.homeNav {
  font-size: 18px;
}

.homeSection {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
}

.logoBox {
  min-width: 350px;
}

.content {
  min-width: 530px;
  align-items: center;
}

.contentHeader {
  font-size: 35px;
  font-weight: 900;
}

.contentText {
  font-size: 25px;
  font-weight: 700;
}

.strike {
  top: -20px;
}

.MuiGrid-root td.column-9,
.MuiGrid-root th.column-9 {
  display:none;
  width:0;
  height:0;
  opacity:0;
  visibility: collapse;       
}
.MuiGrid-root td.column-8,
.MuiGrid-root th.column-8 {
  display:none;
  width:0;
  height:0;
  opacity:0;
  visibility: collapse;       
}
.MuiGrid-root td.column-7,
.MuiGrid-root th.column-7 {
  display:none;
  width:0;
  height:0;
  opacity:0;
  visibility: collapse;       
}
.MuiGrid-root td.column-6,
.MuiGrid-root th.column-6 {
  display:none;
  width:0;
  height:0;
  opacity:0;
  visibility: collapse;       
}

.MuiGrid-root td.column-5,
.MuiGrid-root th.column-5 {
  display:none;
  width:0;
  height:0;
  opacity:0;
  visibility: collapse;       
}

.MuiGrid-root td.column-4,
.MuiGrid-root th.column-4 {
  display:none;
  width:0;
  height:0;
  opacity:0;
  visibility: collapse;       
}

@media all and (max-width: 1040px) {
  .homeTitle {
    margin-left: 10px;
  }
}

@media all and (max-width: 880px) {
  .homeTitle {
    margin-left: 10px;
  }
  .homeSection {
    height: auto;
  }
}

@media all and (max-width: 850px) {
  td.column-9,
  th.column-9 {
    display:none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;       
  }
}
@media all and (max-width: 750px) {
  td.column-8,
  th.column-8 {
    display:none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;       
  }
}
@media all and (max-width: 650px) {
  td.column-7,
  th.column-7 {
    display:none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;       
  }
  .content {
    min-width: 530px;
  }
  .contentHeader {
    font-size: 25px;
  }
  .contentText {
    font-size: 15px;
  }
  .homeSection {
    padding: 0;
  }
  .strike {
    top: -13px;
  }
}
@media all and (max-width: 550px) {
  td.column-6,
  th.column-6 {
    display:none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;       
  }
  .homeTitle {
    font-size: 13px;
  }
  .homeNav {
    font-size: 16px;
  }
  .content {
    min-width: 400px;
  }
  .logoBox {
    min-width: 200px;
  }
  .contentHeader {
    padding: 0 20px;
  }
}
@media all and (max-width: 450px) {
  td.column-5,
  th.column-5 {
    display:none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;       
  }
  .content {
    min-width: 300px;
    align-items: flex-start;
  }
  .logoBox {
    min-width: 150px;
  }
  .homeTitle {
    font-size: 10px;
  }
  .homeNav {
    font-size: 12px;
  }
}
@media all and (max-width: 350px) {
  td.column-4,
  th.column-4 {
    display:none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;       
  }
  .homeTitle {
    font-size: 9px;
  }
  .homeNav {
    font-size: 10px;
  }
}
@media all and (min-width: 300px) {
  td.column-3,
  th.column-3 {
    display:none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;       
  }
}

h4 {
  font-size: 3em;
}

.fc-scroller-harness .fc-scroller-liquid-absolute {
  overflow:hidden !important;
}
